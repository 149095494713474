<template>
  <v-card class="pa-2">
    <v-data-iterator item-key="IdCliente" :items="value" :search="search" :footer-props="{
					showCurrentPage: true,
				}">
      <template v-slot:header>
        <div class="d-flex align-center">
          <v-subheader>Abonos pendientes por cliente</v-subheader>
          <v-spacer />
          <v-text-field
            dense
            hide-details
            filled
            prepend-inner-icon="mdi-magnify"
            v-model="search"
          />
          <v-btn
            color="primary"
            small
            class="ml-1"
            text
            @click.stop="$emit('exportar', value, 'abonosPendientesPorCliente')"
          >
            <v-icon left>mdi-download</v-icon>Exportar
          </v-btn>
        </div>
        <v-divider />
      </template>

      <template v-slot:default="{ items }">
        <v-list dense>
          <v-list-item v-for="item in items" :key="item.IdCliente">
            <v-list-item-content>
              <div class="d-flex">
                <v-btn
                  text
                  color="secondary"
                  small
                  :to="`/clientes/detalles?idCliente=${item.IdCliente}`"
                >{{ item.denominacion }}</v-btn>
              </div>
            </v-list-item-content>
            <v-list-item-action>{{ parseFloat(item.deuda).toFixed(2) }} €</v-list-item-action>
          </v-list-item>
        </v-list>
      </template>
    </v-data-iterator>
  </v-card>
</template>

<script>
export default {
  props: {
    value: { type: Array, optional: false },
  },
  data() {
    return {
      search: null
    }
  }
};
</script>

<style scoped>
.v-data-iterator >>> .v-data-footer__select {
  display: none !important;
}
.v-data-iterator >>> .v-data-footer__pagination {
  margin-left: auto !important;
}
</style>